<template>
	<div :class="$style.auth">
		<div v-if="pdfPreview.show" class="page_preview hide-scrollbar show" style="overflow-y:scroll">
			<a-icon type="close" class="close_button cursor-pointer text-white text-xl"
				@click="pdfPreview.show = false" />
			<iframe v-if="pdfPreview.url" :src="pdfPreview.url" id="iframe" loading="lazy"></iframe>
		</div>
		<a-spin v-else :spinning="loading">
			<div class="py-4 dF jC align-items-end mt-auto">
				<img src="@/assets/logo.svg" alt="Bildhive Logo" style="height:40px;" />
			</div>
			<transition>
				<div class="px-5 py-4 bg-white container">
					<div v-if="justPaid">
						<div class="text-center text-dark font-size-30">Reservation Payment</div>
						<div class="text-center text-dark-gray font-size-16 my-5">
							You have successfully paid reservation fee
							<strong>${{ reservationLink.amount }} {{ currency === 'CAD' ? '(CAD)' : '' }}</strong>
							<span v-if="reservationLink.lot">
								for <strong>Lot {{ reservationLink.lot.name }} ({{ reservationLink.unit.unitGroup &&
									reservationLink.unit.unitGroup.name }} - {{ reservationLink.unit.name }})</strong>.
							</span>
							<span v-else-if="reservationLink.unit">
								for <strong>Unit {{ reservationLink.unit && reservationLink.unit.unitNumber }}</strong>.
							</span>
						</div>
					</div>
					<div v-else-if="['paid', 'completed'].includes(reservationLink.status)">
						<div class="text-center text-dark font-size-30">Reservation Payment</div>
						<div class="text-center text-dark-gray font-size-16 my-5">
							You have already paid the reservation amount
							<strong>${{ reservationLink.amount }} {{ currency === 'CAD' ? '(CAD)' : '' }}</strong>
							<span v-if="reservationLink.lot">
								for <strong>Lot {{ reservationLink.lot.name }} ({{ reservationLink.unit.unitGroup &&
									reservationLink.unit.unitGroup.name }} - {{ reservationLink.unit.name }})</strong>.
							</span>
							<span v-else-if="reservationLink.unit">
								for <strong>Unit {{ reservationLink.unit && reservationLink.unit.unitNumber }}</strong>.
							</span>
						</div>
					</div>
					<div v-else-if="reservationLink.status === 'void'">
						<div class="text-center text-dark font-size-30">Reservation Payment</div>
						<div class="text-center text-dark-gray font-size-16 my-5">
							Reservation for Unit <strong>{{ reservationLink.unit &&
								reservationLink.unit.unitNumber }}</strong> is voided.
							Please contact you agent before processing further.
						</div>
					</div>
					<div v-else>
						<a-steps :current="currentStep" class="mb-3">
							<a-step title="Step 1" description="Reservation Info" />
							<a-step title="Step 2" description="Payment Details" />
						</a-steps>
						<div v-if="currentStep === 0">
							<h4 class="text-center text-dark font-size-28">
								Offer Details
							</h4>
							<a-card v-if="reservationLink.lot" class="my-3">
								<a-descriptions layout="horizontal" :column="3">
									<a-descriptions-item label="Reservation Amount">
										${{ reservationLink.amount.toLocaleString("US") }} {{ currency ===
											'CAD' ? '(CAD)' : '' }}
									</a-descriptions-item>
									<a-descriptions-item label="Lot #">
										{{ reservationLink.lot && reservationLink.lot.name }}
									</a-descriptions-item>
									<a-descriptions-item label="Unit #">
										{{ reservationLink.unit && reservationLink.unit.unitGroup &&
											reservationLink.unit.unitGroup.name }}
									</a-descriptions-item>

									<a-descriptions-item label="Unit Name">
										{{ reservationLink.unit && reservationLink.unit.name }}
									</a-descriptions-item>

									<a-descriptions-item label="Unit Size">
										{{ reservationLink.unit && reservationLink.unit.unitGroup &&
											reservationLink.unit.unitGroup.size || '' }}'
										{{ reservationLink.unit && reservationLink.unit.unitGroup &&
											reservationLink.unit.unitGroup.type || '' }}
									</a-descriptions-item>
								</a-descriptions>
							</a-card>
							<a-card v-else-if="reservationLink.unit" class="my-3">
								<a-descriptions layout="horizontal" :column="3">
									<a-descriptions-item label="Reservation Amount">
										${{ reservationLink.amount.toLocaleString("US") }} {{ currency ===
											'CAD' ? '(CAD)' : '' }}
									</a-descriptions-item>
									<a-descriptions-item label="Floor #">
										{{ reservationLink.unit.unitGroup && reservationLink.unit.unitGroup.name }}
									</a-descriptions-item>
									<a-descriptions-item label="Unit #">
										{{ reservationLink.unit.unitNumber }}
									</a-descriptions-item>
									<a-descriptions-item label="Unit Code">
										{{ reservationLink.unit.unitCode }}
									</a-descriptions-item>
									<a-descriptions-item label="Marketing Name">
										{{ reservationLink.unit.name }}
									</a-descriptions-item>
									<a-descriptions-item label="Unit Price">
										${{ reservationLink.unit.packages && reservationLink.unit.packages[0] &&
											reservationLink.unit.packages[0].price &&
											reservationLink.unit.packages[0].price.toLocaleString("US") }}
									</a-descriptions-item>
									<a-descriptions-item label="Unit Interior Square Footage">
										{{ reservationLink.unit.packages && reservationLink.unit.packages[0] &&
											reservationLink.unit.packages[0].sqft &&
											reservationLink.unit.packages[0].sqft.toLocaleString("US") }}
									</a-descriptions-item>
									<a-descriptions-item label="Unit Exterior Square Footage">
										{{ reservationLink.unit.packages && reservationLink.unit.packages[0] &&
											reservationLink.unit.packages[0].extSqft &&
											reservationLink.unit.packages[0].extSqft.toLocaleString("US") }}
									</a-descriptions-item>
									<a-descriptions-item label="Unit Exposure">
										{{ reservationLink.unit.packages && reservationLink.unit.packages[0] &&
											reservationLink.unit.packages[0].exposure &&
											(reservationLink.unit.packages[0].exposure).charAt(0).toUpperCase() +
											reservationLink.unit.packages[0].exposure.slice(1) }}
									</a-descriptions-item>
									<a-descriptions-item label="Bedrooms">
										{{ reservationLink.unit.packages && reservationLink.unit.packages[0] &&
											reservationLink.unit.packages[0].beds }}
									</a-descriptions-item>
									<a-descriptions-item label="Bathrooms">
										{{ reservationLink.unit.packages && reservationLink.unit.packages[0] &&
											reservationLink.unit.packages[0].baths }}
									</a-descriptions-item>
								</a-descriptions>
							</a-card>
							<div class="my-3"
								v-if="reservationLink.addons && Object.keys(reservationLink.addons).length">
								<h4 class="text-center text-dark font-size-28">
									Add Ons Details
								</h4>

								<a-tabs default-active-key="1" size="large">
									<a-tab-pane key="1" tab="Parking Spot">
										<a-table :rowKey="(e) => e.id" :columns="parkingColumns"
											:data-source="reservationLink.addons.parking || []" :pagination="false" />
									</a-tab-pane>
									<a-tab-pane key="2" tab="Locker">
										<a-table :rowKey="(e) => e.id" :columns="lockerColumns"
											:data-source="reservationLink.addons.lockers || []" :pagination="false" />
									</a-tab-pane>
									<a-tab-pane key="3" tab="Bike Rack">
										<a-table :rowKey="(e) => e.id" :columns="bikeColumns"
											:data-source="reservationLink.addons.bikeRacks || []" :pagination="false" />
									</a-tab-pane>
								</a-tabs>
							</div>

							<div class="dF aC jE" style="gap: 20px">
								<a-tooltip title="Preview the reservation agreement"
									overlayClassName="change-tooltip-color">
									<a-button icon="eye" @click="previewReservation" size="large">Preview</a-button>
								</a-tooltip>
								<a-button size="large" type="primary" @click="currentStep++">NEXT</a-button>
							</div>
						</div>
						<div v-else-if="currentStep === 1">
							<div class="text-center text-dark font-size-28">Payment Details</div>
							<div class="text-center text-dark-gray font-size-16 mb-3">
								Please enter your card information to pay the reservation amount
							</div>
							<a-form-model ref="paymentMethod" :model="paymentMethod" class="profile">
								<a-row>
									<a-col :span="24">
										<a-row :gutter="16">
											<a-col :span="$mq == 'sm' ? 24 : 12">
												<a-form-model-item label="Reservation Amount" required>
													<a-input size="large"
														:value="`$ ${reservationLink.amount} ${currency === 'CAD' ? '(CAD)' : ''}`"
														placeholder="Reservation Amount" disabled />
												</a-form-model-item>
											</a-col>
											<a-col :span="$mq == 'sm' ? 24 : 12">
												<a-form-model-item label="Select payment currency" required>
													<a-select v-model="currency" :default-value="{ key: 'USD' }"
														style="width: 100%" @change="onCurrencyChange" size="large">
														<a-select-option value="USD">
															$ USD
														</a-select-option>
														<a-select-option value="CAD">
															$ CAD
														</a-select-option>
													</a-select>
												</a-form-model-item>
											</a-col>
										</a-row>

										<a-row :gutter="16">
											<a-col :span="$mq == 'sm' ? 24 : 12">
												<a-form-model-item prop="cardNo"
													:class="{ 'valid-card': numberValidation.isValid, 'valid-type': numberValidation.isPotentiallyValid }"
													label="Credit Card Number" required
													:rules="req('Please enter the Card Number')">
													<a-input v-model="paymentMethod.cardNo"
														placeholder="0000 - 0000 - 0000 - 0000" :maxLength="16"
														size="large" required>
													</a-input>
												</a-form-model-item>
											</a-col>
											<a-col :span="$mq == 'sm' ? 24 : 12">
												<a-form-model-item prop="cardName" label="Cardholder Name" required
													:rules="req('Please enter the Cardholder Name')">
													<a-input v-model="paymentMethod.cardName"
														placeholder="Cardholder Name" size="large" required>
													</a-input>
												</a-form-model-item>
											</a-col>
										</a-row>

										<a-row :gutter="16">
											<a-col :span="$mq == 'sm' ? 24 : 12">
												<a-form-model-item label="Expiry Date" required
													:rules="req('Please enter the Expiry Date')" prop="expiry">
													<a-input size="large" v-model="paymentMethod.expiry"
														placeholder="MM/YY" :maxLength="5" @change="onExpiryUpdate" />
												</a-form-model-item>
											</a-col>
											<a-col :span="$mq == 'sm' ? 24 : 12">
												<a-form-model-item label="CCV/CVV Code" required
													:rules="req('Please enter the CCV/CVV code')" prop="cvv">
													<a-input size="large" v-model="paymentMethod.cvv" placeholder="XXX"
														:maxLength="4" />
												</a-form-model-item>
											</a-col>
										</a-row>
									</a-col>
								</a-row>
								<hr />
								<a-row>
									<a-col :span="24">
										<a-row :gutter="16">
											<a-col :span="$mq == 'sm' ? 24 : 12">
												<a-form-model-item label="Address" required prop="address.one"
													:rules="req('Please enter the Address')">
													<a-input v-model="paymentMethod.address.one" placeholder="Address"
														size="large" required>
													</a-input>
												</a-form-model-item>
											</a-col>
											<a-col :span="$mq == 'sm' ? 24 : 12">
												<a-form-model-item label="Country" required prop="address.country"
													:rules="req('Please enter the Country')">
													<a-select show-search placeholder="Choose a country" size="large"
														option-filter-prop="children" :filter-option="filterOption"
														v-model="paymentMethod.address.country" @change="
															paymentMethod.address.region = null
															">
														<a-select-option value="US">
															United States
														</a-select-option>
														<a-select-option value="CA">
															Canada
														</a-select-option>
													</a-select>
												</a-form-model-item>
											</a-col>
										</a-row>

										<a-row :gutter="16">
											<a-col :span="$mq == 'sm' ? 24 : 8">
												<a-form-model-item label="City" required prop="address.city"
													:rules="req('Please enter the City')">
													<a-input v-model="paymentMethod.address.city" placeholder="City"
														size="large" required>
													</a-input>
												</a-form-model-item>
											</a-col>
											<a-col :span="$mq == 'sm' ? 24 : 8">
												<a-form-model-item label="State/Province" required
													:rules="req('Please enter the Province/State')"
													prop="address.region">
													<a-select show-search placeholder="Choose a state/province"
														size="large" option-filter-prop="children"
														:filter-option="filterOption"
														v-model="paymentMethod.address.region">
														<template v-if="paymentMethod.address.country === 'US'">
															<a-select-option v-for="(opt, optI) in states"
																:key="opt.name + optI" :value="opt.name">
																{{ opt.name }}
															</a-select-option>
														</template>
														<template v-else>
															<a-select-option v-for="(opt, optI) in provinces"
																:key="opt.name + optI" :value="opt.name">
																{{ opt.name }}
															</a-select-option>
														</template>
													</a-select>
												</a-form-model-item>
											</a-col>
											<a-col :span="$mq == 'sm' ? 24 : 8">
												<a-form-model-item label="Zip/Postal Code" required
													:rules="req('Please enter the Postal Code')" prop="address.zipcode">
													<a-input size="large" v-model="paymentMethod.address.zipcode"
														placeholder="Zip/Postal Code" />
												</a-form-model-item>
											</a-col>
										</a-row>
									</a-col>
									<a-col :span="24">
										<a-checkbox v-model="iAgree">
											I agree to the terms and conditions as outlined in the Reservation
											Agreement.
										</a-checkbox>
									</a-col>
								</a-row>
							</a-form-model>
							<div class="dF aC jSB mt-5">
								<a-button size="large" type="secondary" @click="currentStep--">BACK</a-button>
								<div class="dF aC jE" style="gap: 20px">
									<a-tooltip title="Preview the reservation agreement"
										overlayClassName="change-tooltip-color">
										<a-button icon="eye" @click="previewReservation" size="large">Preview</a-button>
									</a-tooltip>
									<a-button size="large" type="primary" :disabled="!iAgree" :loading="loading"
										@click="payReservationAmount">
										MAKE A PAYMENT
									</a-button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</transition>
			<footer-bh />
		</a-spin>
	</div>
</template>

<script>
import valid from "card-validator";
import FooterBh from "@/components/common/FooterTerms.vue";

export default {
	name: "Reservation-Payment",
	components: { FooterBh },
	data() {
		return {
			loading: false,
			justPaid: false,
			iAgree: false,
			currency: 'USD',
			currentStep: 0,
			reservationLink: {
				id: null,
				status: null,
				amount: null,
				unit: null,
				createdBy: null,
			},
			paymentMethod: {
				ccBrand: "",
				cardNo: "",
				expiry: "",
				cardName: "",
				cvv: "",
				address: {
					one: "",
					two: "",
					region: "",
					city: "",
					country: "US",
					zipcode: "",
				},
			},
			numberValidation: {
				card: {
					niceType: "",
					type: "",
					patterns: [4],
					gaps: [4, 8, 12],
					lengths: [16, 18, 19],
					code: { name: "CVV", size: 3 },
					matchStrength: 1,
				},
				isPotentiallyValid: false,
				isValid: false,
			},
			states: [
				{ name: "Alabama", value: "AL" },
				{ name: "Alaska", value: "AK" },
				{ name: "Arizona", value: "AZ" },
				{ name: "Arkansas", value: "AR" },
				{ name: "California", value: "CA" },
				{ name: "Colorado", value: "CO" },
				{ name: "Connecticut", value: "CT" },
				{ name: "Delaware", value: "DE" },
				{ name: "District of Columbia", value: "DC" },
				{ name: "Florida", value: "FL" },
				{ name: "Georgia", value: "GA" },
				{ name: "Hawaii", value: "HI" },
				{ name: "Idaho", value: "ID" },
				{ name: "Illinois", value: "IL" },
				{ name: "Indiana", value: "IN" },
				{ name: "Iowa", value: "IA" },
				{ name: "Kansas", value: "KS" },
				{ name: "Kentucky", value: "KY" },
				{ name: "Louisiana", value: "LA" },
				{ name: "Maine", value: "ME" },
				{ name: "Maryland", value: "MD" },
				{ name: "Massachusetts", value: "MA" },
				{ name: "Michigan", value: "MI" },
				{ name: "Minnesota", value: "MN" },
				{ name: "Mississippi", value: "MS" },
				{ name: "Missouri", value: "MO" },
				{ name: "Montana", value: "MT" },
				{ name: "Nebraska", value: "NE" },
				{ name: "Nevada", value: "NV" },
				{ name: "New Hampshire", value: "NH" },
				{ name: "New Jersey", value: "NJ" },
				{ name: "New Mexico", value: "NM" },
				{ name: "New York", value: "NY" },
				{ name: "North Carolina", value: "NC" },
				{ name: "North Dakota", value: "ND" },
				{ name: "Ohio", value: "OH" },
				{ name: "Oklahoma", value: "OK" },
				{ name: "Oregon", value: "OR" },
				{ name: "Pennsylvania", value: "PA" },
				{ name: "Rhode Island", value: "RI" },
				{ name: "South Carolina", value: "SC" },
				{ name: "South Dakota", value: "SD" },
				{ name: "Tennessee", value: "TN" },
				{ name: "Texas", value: "TX" },
				{ name: "Utah", value: "UT" },
				{ name: "Vermont", value: "VT" },
				{ name: "Virginia", value: "VA" },
				{ name: "Washington", value: "WA" },
				{ name: "West Virginia", value: "WV" },
				{ name: "Wisconsin", value: "WI" },
				{ name: "Wyoming", value: "WY" },
			],
			provinces: [
				{ name: "Alberta", value: "AB" },
				{ name: "British Columbia", value: "BC" },
				{ name: "Manitoba", value: "MB" },
				{ name: "New Brunswick", value: "NB" },
				{ name: "Newfoundland and Labrador", value: "NL" },
				{ name: "Nova Scotia", value: "NS" },
				{ name: "Ontario", value: "ON" },
				{ name: "Prince Edward Island", value: "PE" },
				{ name: "Quebec", value: "QC" },
				{ name: "Saskatchewan", value: "SK" },
				{ name: "Northwest Territories", value: "NT" },
				{ name: "Nunavut", value: "NU" },
				{ name: "Yukon", value: "YT" },
			],
			parkingColumns: [
				{
					dataIndex: "pID",
					key: "pID",
					title: "Parking ID",
					sorter: (a, b) => a.pID < b.pID
						? -1
						: a.pID > b.pID
							? 1
							: 0,
				},
				{
					dataIndex: "name",
					key: "name",
					title: "Parking Spot #",
					sorter: (a, b) => a.name < b.name
						? -1
						: a.name > b.name
							? 1
							: 0,
				},
				{
					dataIndex: "type",
					key: "type",
					title: "Parking Spot Type",
					sorter: (a, b) => a.type < b.type
						? -1
						: a.type > b.type
							? 1
							: 0,
				},
				{
					dataIndex: "price",
					key: "price",
					title: "Parking Spot Price",
					sorter: (a, b) => a.price - b.price,
				},
				{
					dataIndex: "description",
					key: "description",
					title: "Parking Spot Description",
					sorter: (a, b) => a.description < b.description
						? -1
						: a.description > b.description
							? 1
							: 0,
				}
			],
			lockerColumns: [
				{
					dataIndex: "lID",
					key: "lID",
					title: "Locker ID",
					sorter: (a, b) => a.lID < b.lID
						? -1
						: a.lID > b.lID
							? 1
							: 0,
				},
				{
					dataIndex: "name",
					key: "name",
					title: "Locker #",
					sorter: (a, b) => a.name < b.name
						? -1
						: a.name > b.name
							? 1
							: 0,
				},
				{
					dataIndex: "type",
					key: "type",
					title: "Locker Type",
					sorter: (a, b) => a.type < b.type
						? -1
						: a.type > b.type
							? 1
							: 0,
				},
				{
					dataIndex: "price",
					key: "price",
					title: "Locker Price",
					sorter: (a, b) => a.price - b.price,
				},
				{
					dataIndex: "description",
					key: "description",
					title: "Locker Description",
					sorter: (a, b) => a.description < b.description
						? -1
						: a.description > b.description
							? 1
							: 0,
				}
			],
			bikeColumns: [
				{
					dataIndex: "bID",
					key: "bID",
					title: "Bike Rack ID",
					sorter: (a, b) => a.bID < b.bID
						? -1
						: a.bID > b.bID
							? 1
							: 0,
				},
				{
					dataIndex: "name",
					key: "name",
					title: "Bike Rack #",
					sorter: (a, b) => a.name < b.name
						? -1
						: a.name > b.name
							? 1
							: 0,
				},
				{
					dataIndex: "price",
					key: "price",
					title: "Bike Rack Price",
					sorter: (a, b) => a.price - b.price,
				},
				{
					dataIndex: "description",
					key: "description",
					title: "Bike Rack Description",
					sorter: (a, b) => a.description < b.description
						? -1
						: a.description > b.description
							? 1
							: 0,
				},
			],
			pdfPreview: {
				show: false,
				url: ''
			}
		};
	},
	watch: {
		paymentMethod: {
			handler(newCard) {
				let { cardNo } = newCard;
				let nums = valid.number(cardNo);
				this.numberValidation = nums;
			},
			deep: true,
		},
		numberValidation: {
			handler(newCard) {
				let table = {
					express: "amex",
					mastercard: "mc",
					visa: "visa",
				};
				if (
					newCard.isPotentiallyValid &&
					newCard.card &&
					newCard.card.niceType
				) {
					let ccBrand = newCard.card.niceType.toLowerCase();
					let foundKey = Object.keys(table).find((x) =>
						ccBrand && ccBrand.includes(x)
					);
					if (foundKey) {
						let filename = table[foundKey];
						this.paymentMethod.ccBrand = filename;
					} else {
						this.paymentMethod.ccBrand = "";
					}
				} else {
					this.numberValidation.src = require(`@/assets/cc/error.png`);
				}
			},
			deep: true,
		},
	},
	async created() {
		await this.getReservationData();
		this.currency = this.reservationLink.currency || 'USD';
	},
	methods: {
		req: (msg) => ({ required: true, message: msg }),
		filterOption(input, option) {
			return (
				option.componentOptions.children[0].text
					.toLowerCase()
					.indexOf(input.toLowerCase()) >= 0
			);
		},
		payReservationAmount() {
			if (!this.iAgree) {
				return this.$message.error(
					"Please agree to the terms and conditions to continue"
				);
			}
			this.$refs.paymentMethod.validate(async (valid2) => {
				if (valid2) {
					let expiry = valid.expirationDate(
						this.paymentMethod.expiry
					);
					if (!this.numberValidation.isValid)
						return this.$message.error(
							"Card Number is invalid. Please enter correct information to move forward."
						);
					if (!expiry.isValid)
						return this.$message.error(
							"Card Expiry is invalid. Please enter correct information to move forward."
						);
					let sendObj = JSON.parse(
						JSON.stringify(this.paymentMethod)
					);

					this.loading = true;
					try {
						await this.$api.post(`v1/reservations/${this.$route.params.id}/collect-payment-for-reservation?token=${this.$route.query.instanceId}`, { card: sendObj, currency: this.currency })
						this.$message.success('You have successfully paid reservation fee for your unit');
						this.justPaid = true;
					} catch (err) {
						console.error('Error while making reservation payment', err)
						this.$message.error(
							(err.response &&
								err.response.data &&
								err.response.data.message) ||
							"Error while making reservation payment. Please try again!"
						);
					}
					this.loading = false;
				} else {
					return false;
				}
			});
		},

		async getReservationData(currency) {
			this.loading = true;
			try {
				const { data } = await this.$api.get(`v1/reservations/${this.$route.params.id}?token=${this.$route.query.instanceId}${currency ? '&currency=' + currency : ''}`);
				this.reservationLink = { ...data }
			} catch (err) {
				this.currency = this.currency === 'USD' ? 'CAD' : 'USD'
				console.error('Error while fetching reservation data', err)
				this.$message.error(
					(err.response &&
						err.response.data &&
						err.response.data.message) ||
					"Error while fetching reservation data. Please try again!"
				);
			}
			this.loading = false;
		},

		onCurrencyChange() {
			this.getReservationData(this.currency);
		},

		async previewReservation() {
			if (this.pdfPreview.url) {
				return this.pdfPreview.show = true;
			}
			let key
			let record = this.reservationLink.envelops && this.reservationLink.envelops[0];
			if (!record) {
				return this.$message.error('No reservation agreement found')
			}
			if (record.isManual) {
				let index = record.file && record.file.length ? (record.file.length - 1) : 0;
				if (!record.file || !record.file[index] || !record.file[index].key) {
					return this.$message.error('Cannot find signed PDF file')
				}
				key = record.file[index].key
			} else {
				if (!record.download || !record.download.combined || !record.download.combined.key) {
					return this.$message.error('Cannot find signed PDF file')
				}
				key = record.download.combined.key
			}

			this.loading = true;
			try {
				let { data: url } = await this.$api.post(`/v1/files/get-signed-url?token=${this.$route.query.instanceId}`, { key })
				this.pdfPreview.url = url;
				this.pdfPreview.show = true;
			} catch (err) {
				if (!err || !err.response || !err.response.status || err.response.status !== 400) {
					this.$message.error('There was an error downloading the reservation file. Please try again')
				}
			}
			this.loading = false
		},

		onExpiryUpdate(event) {
			let expiry = event.target.value;
			if (expiry.includes('//')) {
				this.paymentMethod.expiry = expiry.replace('/', '');
			} else if (expiry && expiry.length === 3 && expiry.includes('/') && event.target._value && event.target._value.includes('/')) {
				this.paymentMethod.expiry = expiry.replace('/', '');
			} else if (expiry && expiry.length >= 2 && !event.target._value.includes('/') && !expiry.includes('/')) {
				this.paymentMethod.expiry = expiry.substr(0, 2) + '/' + expiry.substr(2)
			}
		}
	},
};
</script>

<style lang="scss" module>
@import "@/components/system/Auth/style.module.scss";
</style>

<style lang="scss" scoped>
.container {
	min-width: 600px;
	max-width: 800px;
	width: 100%;
	height: 100%;
	box-shadow: 0 4px 38px 0 rgba(22, 21, 55, 0.06), 0 0 21px 0 rgba(22, 21, 55, 0.03);
}
</style>

<style lang="scss">
.page_preview {
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, .6);
	display: flex;
	z-index: 1005;
	opacity: 0;
	pointer-events: none;
	transition: opacity .3s ease-out;
	justify-content: center;
	align-items: center;

	&.show {
		opacity: 1;
		pointer-events: all;
	}

	.close_button {
		position: absolute;
		top: 20px;
		right: 20px;
	}

	canvas,
	iframe {
		width: 100%;
		flex: 1;
	}

	&:not(.confirm) {
		.page-preview-action {
			display: none;
		}

		canvas,
		iframe {

			width: 80%;
			height: 90%;
		}
	}
}

.page-preview-action {
	height: 100px;
}
</style>
